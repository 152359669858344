.icons_small__30g4C {
  width: var(--size--icon--small);
  height: var(--size--icon--small); }

.icons_medium__GRFtn {
  width: var(--size--icon--medium);
  height: var(--size--icon--medium); }

.icons_large__2UaLr {
  width: var(--size--icon--default);
  height: var(--size--icon--default); }

.icons_responsive__3PqJ0 {
  width: var(--size--icon--responsive);
  height: var(--size--icon--responsive); }

.icons_deg0__2fo9U {
  transition: var(--animation--fast);
  transform: rotate(0deg); }

.icons_deg90__2SpxO {
  transition: var(--animation--fast);
  transform: rotate(90deg); }

.icons_deg180__-DKdr {
  transition: var(--animation--fast);
  transform: rotate(180deg); }

.icons_deg270__2DPkI {
  transition: var(--animation--fast);
  transform: rotate(270deg); }
