.heading_h1__1qtmB {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--xxx-large);
  font-weight: var(--font-weight--medium);
  color: var(--color--text--static--header); }
  .heading_h1__1qtmB:not(.heading_line-height-single-line__2CZGM) {
    line-height: var(--line-height--x-small); }

.typography-text-container > .heading_h1__1qtmB {
  margin-bottom: var(--space--xxx-large); }

.typography-text-container.typography-article-container > .heading_h1__1qtmB {
  font-weight: var(--font-weight--bold); }

.heading_h2__3sdu4 {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--large);
  font-weight: var(--font-weight--bold);
  color: var(--color--text--static--header); }
  .heading_h2__3sdu4:not(.heading_line-height-single-line__2CZGM) {
    line-height: var(--line-height--small); }

.typography-text-container > .heading_h2__3sdu4 {
  font-size: var(--font-size--x-large);
  margin-top: var(--space--xxx-large);
  margin-bottom: var(--space--medium); }

.typography-text-container.typography-article-container > .heading_h2__3sdu4 {
  font-size: var(--font-size--xx-large);
  margin-bottom: var(--space--large); }

.heading_h3__2qIXu {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--large);
  font-weight: var(--font-weight--medium);
  color: var(--color--text--static--header); }
  .heading_h3__2qIXu:not(.heading_line-height-single-line__2CZGM) {
    line-height: var(--line-height--small); }

.typography-text-container > .heading_h3__2qIXu {
  margin-top: var(--space--medium);
  margin-bottom: var(--space--small); }

.typography-text-container.typography-article-container > .heading_h3__2qIXu {
  font-size: var(--font-size--x-large);
  margin-top: var(--space--x-large);
  margin-bottom: var(--space--medium); }

.heading_h4__3hAte {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--medium);
  font-weight: var(--font-weight--medium);
  color: var(--color--text--static--header); }
  .heading_h4__3hAte:not(.heading_line-height-single-line__2CZGM) {
    line-height: var(--line-height--medium); }

.typography-text-container > .heading_h4__3hAte {
  line-height: var(--line-height--medium);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container > .heading_h4__3hAte {
  font-size: var(--font-size--large);
  margin-top: var(--space--large);
  margin-bottom: var(--space--x-small); }

.heading_h5__tdrU1 {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--normal);
  color: var(--color--text--static--header);
  letter-spacing: 0.0625rem;
  text-transform: uppercase; }
  .heading_h5__tdrU1:not(.heading_line-height-single-line__2CZGM) {
    line-height: var(--line-height--small); }

.typography-text-container > .heading_h5__tdrU1 {
  line-height: var(--line-height--small);
  margin-top: var(--space--small);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container > .heading_h5__tdrU1 {
  font-size: var(--font-size--medium);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.heading_h6__zycmo {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--bold);
  color: var(--color--text--static--header);
  font-family: var(--font-family--default); }
  .heading_h6__zycmo:not(.heading_line-height-single-line__2CZGM) {
    line-height: var(--line-height--medium); }

.typography-text-container > .heading_h6__zycmo {
  line-height: var(--line-height--medium);
  margin-top: var(--space--small);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container > .heading_h6__zycmo {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.typography-text-container > .heading_h1__1qtmB:first-child,
.typography-text-container > .heading_h2__3sdu4:first-child,
.typography-text-container > .heading_h3__2qIXu:first-child,
.typography-text-container > .heading_h4__3hAte:first-child,
.typography-text-container > .heading_h5__tdrU1:first-child,
.typography-text-container > .heading_h6__zycmo:first-child,
.typography-text-container.typography-article-container > .heading_h1__1qtmB:first-child,
.typography-text-container.typography-article-container > .heading_h2__3sdu4:first-child,
.typography-text-container.typography-article-container > .heading_h3__2qIXu:first-child,
.typography-text-container.typography-article-container > .heading_h4__3hAte:first-child,
.typography-text-container.typography-article-container > .heading_h5__tdrU1:first-child,
.typography-text-container.typography-article-container > .heading_h6__zycmo:first-child {
  margin-top: 0; }
