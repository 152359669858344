@keyframes spinner_spin__ARlYr {
  to {
    transform: rotate(360deg); } }

@keyframes spinner_fadeIn__2AG1q {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.spinner_spinner__2tsmM {
  margin: auto;
  border: 0.125rem solid color-mix(in srgb, var(--color--white) 50%, transparent);
  border-top-color: var(--color--white);
  border-radius: 50%;
  opacity: 0;
  animation: spinner_spin__ARlYr 0.7s linear infinite, spinner_fadeIn__2AG1q 0.4s ease 1 forwards;
  forced-color-adjust: none; }

.spinner_static-fallback-icon__3rT1t {
  display: none; }

@media (prefers-reduced-motion: reduce) {
  .spinner_spinner__2tsmM {
    border: unset;
    border-radius: unset;
    animation: unset;
    opacity: 1; }
  .spinner_static-fallback-icon__3rT1t {
    display: unset; } }

.spinner_small__29A4_,
.spinner_small__29A4_ .spinner_static-fallback-icon__3rT1t {
  width: var(--size--icon--small);
  height: var(--size--icon--small); }

.spinner_medium__2x6hK,
.spinner_medium__2x6hK .spinner_static-fallback-icon__3rT1t {
  width: var(--size--icon--default);
  height: var(--size--icon--default); }

.spinner_large__uAKz8,
.spinner_large__uAKz8 .spinner_static-fallback-icon__3rT1t {
  width: 3rem;
  height: 3rem;
  border-width: var(--space--xx-small); }

.spinner_light__3olO0 {
  border-color: color-mix(in srgb, var(--color--white) 50%, transparent);
  border-top-color: var(--color--white); }
  .spinner_light__3olO0 .spinner_static-fallback-icon__3rT1t {
    fill: var(--color--white); }

.spinner_dark__3Qj9T {
  border-color: color-mix(in srgb, var(--color--blue) 20%, transparent);
  border-top-color: var(--color--blue); }
  .spinner_dark__3Qj9T .spinner_static-fallback-icon__3rT1t {
    fill: var(--color--blue); }
