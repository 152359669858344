.external-html_line-height-single-line__1u8_A {
  line-height: var(--line-height--reset) !important; }

.external-html_alignment-left__3_QWu {
  text-align: left !important; }

.external-html_alignment-center__uf7Tt {
  text-align: center !important; }

.external-html_alignment-right__3sV75 {
  text-align: right !important; }

.external-html_size-x-small__1TIFa {
  font-size: var(--font-size--x-small) !important; }
  .external-html_size-x-small__1TIFa:not(.external-html_line-height-single-line__1u8_A) {
    line-height: var(--line-height--small) !important; }

.external-html_size-small__dNISV {
  font-size: var(--font-size--small) !important; }
  .external-html_size-small__dNISV:not(.external-html_line-height-single-line__1u8_A) {
    line-height: var(--line-height--medium) !important; }

.external-html_size-medium__1CaeG {
  font-size: var(--font-size--medium) !important; }
  .external-html_size-medium__1CaeG:not(.external-html_line-height-single-line__1u8_A) {
    line-height: var(--line-height--large) !important; }

.external-html_size-large__30efc {
  font-size: var(--font-size--large) !important; }
  .external-html_size-large__30efc:not(.external-html_line-height-single-line__1u8_A) {
    line-height: var(--line-height--medium) !important; }

.external-html_size-x-large__3iX-Z {
  font-size: var(--font-size--x-large) !important; }
  .external-html_size-x-large__3iX-Z:not(.external-html_line-height-single-line__1u8_A) {
    line-height: var(--line-height--medium) !important; }

.external-html_size-xx-large__1KB1g {
  font-size: var(--font-size--xx-large) !important; }
  .external-html_size-xx-large__1KB1g:not(.external-html_line-height-single-line__1u8_A) {
    line-height: var(--line-height--medium) !important; }

.external-html_size-xxx-large__XpNiE {
  font-size: var(--font-size--xxx-large) !important; }
  .external-html_size-xxx-large__XpNiE:not(.external-html_line-height-single-line__1u8_A) {
    line-height: var(--line-height--small) !important; }

.external-html_tone-neutral-dark__2Xvcm {
  color: var(--color--gray--darker) !important; }

.external-html_tone-neutral-light__1b3Uz {
  color: var(--color--white) !important; }

.external-html_tone-subtle__17F0X {
  color: var(--color--typography--tone--subtle) !important; }

.external-html_tone-positive__K-je8 {
  color: var(--color--typography--tone--positive) !important; }

.external-html_tone-negative__3xGqB {
  color: var(--color--typography--tone--negative) !important; }

.external-html_tone-warning__TCc5a {
  color: var(--color--typography--tone--warning) !important; }

.external-html_emphasis-normal__3e_R- {
  font-weight: var(--font-weight--normal) !important; }

.external-html_emphasis-medium__2wSq6 {
  font-weight: var(--font-weight--medium) !important; }

.external-html_emphasis-strong__2qUwP {
  font-weight: var(--font-weight--bold) !important; }

.external-html_h1___vqGQ {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--xxx-large);
  font-weight: var(--font-weight--medium);
  color: var(--color--text--static--header); }
  .external-html_h1___vqGQ:not(.external-html_line-height-single-line__1u8_A) {
    line-height: var(--line-height--x-small); }

.typography-text-container > .external-html_h1___vqGQ {
  margin-bottom: var(--space--xxx-large); }

.typography-text-container.typography-article-container > .external-html_h1___vqGQ {
  font-weight: var(--font-weight--bold); }

.external-html_h2__QqBs0 {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--large);
  font-weight: var(--font-weight--bold);
  color: var(--color--text--static--header); }
  .external-html_h2__QqBs0:not(.external-html_line-height-single-line__1u8_A) {
    line-height: var(--line-height--small); }

.typography-text-container > .external-html_h2__QqBs0 {
  font-size: var(--font-size--x-large);
  margin-top: var(--space--xxx-large);
  margin-bottom: var(--space--medium); }

.typography-text-container.typography-article-container > .external-html_h2__QqBs0 {
  font-size: var(--font-size--xx-large);
  margin-bottom: var(--space--large); }

.external-html_h3__3oK70 {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--large);
  font-weight: var(--font-weight--medium);
  color: var(--color--text--static--header); }
  .external-html_h3__3oK70:not(.external-html_line-height-single-line__1u8_A) {
    line-height: var(--line-height--small); }

.typography-text-container > .external-html_h3__3oK70 {
  margin-top: var(--space--medium);
  margin-bottom: var(--space--small); }

.typography-text-container.typography-article-container > .external-html_h3__3oK70 {
  font-size: var(--font-size--x-large);
  margin-top: var(--space--x-large);
  margin-bottom: var(--space--medium); }

.external-html_h4__3dJji {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--medium);
  font-weight: var(--font-weight--medium);
  color: var(--color--text--static--header); }
  .external-html_h4__3dJji:not(.external-html_line-height-single-line__1u8_A) {
    line-height: var(--line-height--medium); }

.typography-text-container > .external-html_h4__3dJji {
  line-height: var(--line-height--medium);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container > .external-html_h4__3dJji {
  font-size: var(--font-size--large);
  margin-top: var(--space--large);
  margin-bottom: var(--space--x-small); }

.external-html_h5__9GFeK {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--normal);
  color: var(--color--text--static--header);
  letter-spacing: 0.0625rem;
  text-transform: uppercase; }
  .external-html_h5__9GFeK:not(.external-html_line-height-single-line__1u8_A) {
    line-height: var(--line-height--small); }

.typography-text-container > .external-html_h5__9GFeK {
  line-height: var(--line-height--small);
  margin-top: var(--space--small);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container > .external-html_h5__9GFeK {
  font-size: var(--font-size--medium);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.external-html_h6__1-oDe {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--bold);
  color: var(--color--text--static--header);
  font-family: var(--font-family--default); }
  .external-html_h6__1-oDe:not(.external-html_line-height-single-line__1u8_A) {
    line-height: var(--line-height--medium); }

.typography-text-container > .external-html_h6__1-oDe {
  line-height: var(--line-height--medium);
  margin-top: var(--space--small);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container > .external-html_h6__1-oDe {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.typography-text-container > .external-html_h1___vqGQ:first-child,
.typography-text-container > .external-html_h2__QqBs0:first-child,
.typography-text-container > .external-html_h3__3oK70:first-child,
.typography-text-container > .external-html_h4__3dJji:first-child,
.typography-text-container > .external-html_h5__9GFeK:first-child,
.typography-text-container > .external-html_h6__1-oDe:first-child,
.typography-text-container.typography-article-container > .external-html_h1___vqGQ:first-child,
.typography-text-container.typography-article-container > .external-html_h2__QqBs0:first-child,
.typography-text-container.typography-article-container > .external-html_h3__3oK70:first-child,
.typography-text-container.typography-article-container > .external-html_h4__3dJji:first-child,
.typography-text-container.typography-article-container > .external-html_h5__9GFeK:first-child,
.typography-text-container.typography-article-container > .external-html_h6__1-oDe:first-child {
  margin-top: 0; }

.external-html_ol__1KpKA {
  font-family: var(--font-family--default);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--normal);
  color: var(--color--text--static--body);
  line-height: var(--line-height--medium);
  padding: 0;
  margin: 0;
  list-style: outside; }

.typography-text-container > .external-html_ol__1KpKA,
.typography-text-container > .external-html_ol__1KpKA > li > .external-html_ol__1KpKA,
.typography-text-container > .external-html_ol__1KpKA > li > .external-html_ul__2mHuW {
  margin-top: var(--space--x-small);
  margin-bottom: var(--space--small); }

.typography-text-container > .external-html_ol__1KpKA:first-child {
  margin-top: 0; }

.typography-text-container.typography-article-container > .external-html_ol__1KpKA,
.typography-text-container.typography-article-container > .external-html_ol__1KpKA > li > .external-html_ol__1KpKA,
.typography-text-container.typography-article-container > .external-html_ol__1KpKA > li > .external-html_ul__2mHuW {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large);
  margin-top: var(--space--small);
  margin-bottom: var(--space--medium); }

.typography-text-container.typography-article-container > .external-html_ol__1KpKA:first-child {
  margin-top: 0; }

.external-html_ol__1KpKA {
  list-style-type: decimal; }
  .external-html_ol__1KpKA .external-html_li__1WVQv {
    margin: var(--space--xx-small);
    margin-left: var(--space--medium); }

.external-html_ul__2mHuW {
  font-family: var(--font-family--default);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--normal);
  color: var(--color--text--static--body);
  line-height: var(--line-height--medium);
  padding: 0;
  margin: 0;
  list-style: outside; }

.typography-text-container > .external-html_ul__2mHuW,
.typography-text-container > .external-html_ul__2mHuW > li > .external-html_ul__2mHuW,
.typography-text-container > .external-html_ul__2mHuW > li > .external-html_ol__1KpKA {
  margin-top: var(--space--x-small);
  margin-bottom: var(--space--small); }

.typography-text-container > .external-html_ul__2mHuW:first-child {
  margin-top: 0; }

.typography-text-container.typography-article-container > .external-html_ul__2mHuW,
.typography-text-container.typography-article-container > .external-html_ul__2mHuW > li > .external-html_ul__2mHuW,
.typography-text-container.typography-article-container > .external-html_ul__2mHuW > li > .external-html_ol__1KpKA {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large);
  margin-top: var(--space--small);
  margin-bottom: var(--space--medium); }

.typography-text-container.typography-article-container > .external-html_ul__2mHuW:first-child {
  margin-top: 0; }

.external-html_ul__2mHuW .external-html_li__1WVQv {
  margin: var(--space--xx-small);
  margin-left: var(--space--medium); }

/*
	  This will hide the focus indicator if the element receives focus via the mouse,
	  but it will still show up on keyboard focus.
	  More information at: https://github.com/WICG/focus-visible
	*/
html .external-html_external-html__1wWGf a:focus:not(:focus-visible) {
  outline: none; }

html .external-html_external-html__1wWGf a::-moz-focus-inner {
  border: 0; }

.external-html_external-html__1wWGf {
  display: inline-block; }
  .external-html_external-html__1wWGf a {
    color: var(--color--blue--dark);
    font-size: var(--font-size--interactive--default);
    font-weight: var(--font-weight--interactive--default);
    text-decoration: underline; }
    .external-html_external-html__1wWGf a:hover {
      color: var(--color--blue--darker); }
    .external-html_external-html__1wWGf a:active {
      color: var(--color--blue--darkest); }
    .external-html_external-html__1wWGf a:focus-visible {
      box-shadow: 0 0 0 0.125rem var(--color--blue);
      outline: none; }
  .external-html_external-html__1wWGf p {
    font-family: var(--font-family--default);
    font-size: var(--font-size--small);
    font-weight: var(--font-weight--normal);
    color: var(--color--text--static--body);
    line-height: var(--line-height--medium); }
  .external-html_external-html__1wWGf h1 {
    font-family: var(--font-family--heading);
    word-break: break-word;
    margin: 0;
    text-align: left;
    line-height: var(--line-height--reset);
    font-size: var(--font-size--xxx-large);
    font-weight: var(--font-weight--medium);
    color: var(--color--text--static--header); }
    .external-html_external-html__1wWGf h1:not(.external-html_line-height-single-line__1u8_A) {
      line-height: var(--line-height--x-small); }
  .external-html_external-html__1wWGf h2 {
    font-family: var(--font-family--heading);
    word-break: break-word;
    margin: 0;
    text-align: left;
    line-height: var(--line-height--reset);
    font-size: var(--font-size--large);
    font-weight: var(--font-weight--bold);
    color: var(--color--text--static--header); }
    .external-html_external-html__1wWGf h2:not(.external-html_line-height-single-line__1u8_A) {
      line-height: var(--line-height--small); }
  .external-html_external-html__1wWGf h3 {
    font-family: var(--font-family--heading);
    word-break: break-word;
    margin: 0;
    text-align: left;
    line-height: var(--line-height--reset);
    font-size: var(--font-size--large);
    font-weight: var(--font-weight--medium);
    color: var(--color--text--static--header); }
    .external-html_external-html__1wWGf h3:not(.external-html_line-height-single-line__1u8_A) {
      line-height: var(--line-height--small); }
  .external-html_external-html__1wWGf h4 {
    font-family: var(--font-family--heading);
    word-break: break-word;
    margin: 0;
    text-align: left;
    line-height: var(--line-height--reset);
    font-size: var(--font-size--medium);
    font-weight: var(--font-weight--medium);
    color: var(--color--text--static--header); }
    .external-html_external-html__1wWGf h4:not(.external-html_line-height-single-line__1u8_A) {
      line-height: var(--line-height--medium); }
  .external-html_external-html__1wWGf h5 {
    font-family: var(--font-family--heading);
    word-break: break-word;
    margin: 0;
    text-align: left;
    line-height: var(--line-height--reset);
    font-size: var(--font-size--small);
    font-weight: var(--font-weight--normal);
    color: var(--color--text--static--header);
    letter-spacing: 0.0625rem;
    text-transform: uppercase; }
    .external-html_external-html__1wWGf h5:not(.external-html_line-height-single-line__1u8_A) {
      line-height: var(--line-height--small); }
  .external-html_external-html__1wWGf h6 {
    font-family: var(--font-family--heading);
    word-break: break-word;
    margin: 0;
    text-align: left;
    line-height: var(--line-height--reset);
    font-size: var(--font-size--small);
    font-weight: var(--font-weight--bold);
    color: var(--color--text--static--header);
    font-family: var(--font-family--default); }
    .external-html_external-html__1wWGf h6:not(.external-html_line-height-single-line__1u8_A) {
      line-height: var(--line-height--medium); }
  .external-html_external-html__1wWGf ol {
    font-family: var(--font-family--default);
    font-size: var(--font-size--small);
    font-weight: var(--font-weight--normal);
    color: var(--color--text--static--body);
    line-height: var(--line-height--medium);
    padding: 0;
    margin: 0;
    list-style: outside; }
  .external-html_external-html__1wWGf .typography-text-container > ol,
  .external-html_external-html__1wWGf .typography-text-container > ol > li > ol,
  .external-html_external-html__1wWGf .typography-text-container > ol > li > ul {
    margin-top: var(--space--x-small);
    margin-bottom: var(--space--small); }
  .external-html_external-html__1wWGf .typography-text-container > ol:first-child {
    margin-top: 0; }
  .external-html_external-html__1wWGf .typography-text-container.typography-article-container > ol,
  .external-html_external-html__1wWGf .typography-text-container.typography-article-container > ol > li > ol,
  .external-html_external-html__1wWGf .typography-text-container.typography-article-container > ol > li > ul {
    font-size: var(--font-size--medium);
    line-height: var(--line-height--large);
    margin-top: var(--space--small);
    margin-bottom: var(--space--medium); }
  .external-html_external-html__1wWGf .typography-text-container.typography-article-container > ol:first-child {
    margin-top: 0; }
  .external-html_external-html__1wWGf ol {
    list-style-type: decimal; }
    .external-html_external-html__1wWGf ol li {
      margin: var(--space--xx-small);
      margin-left: var(--space--medium); }
  .external-html_external-html__1wWGf ul {
    font-family: var(--font-family--default);
    font-size: var(--font-size--small);
    font-weight: var(--font-weight--normal);
    color: var(--color--text--static--body);
    line-height: var(--line-height--medium);
    padding: 0;
    margin: 0;
    list-style: outside; }
  .external-html_external-html__1wWGf .typography-text-container > ul,
  .external-html_external-html__1wWGf .typography-text-container > ul > li > ul,
  .external-html_external-html__1wWGf .typography-text-container > ul > li > ol {
    margin-top: var(--space--x-small);
    margin-bottom: var(--space--small); }
  .external-html_external-html__1wWGf .typography-text-container > ul:first-child {
    margin-top: 0; }
  .external-html_external-html__1wWGf .typography-text-container.typography-article-container > ul,
  .external-html_external-html__1wWGf .typography-text-container.typography-article-container > ul > li > ul,
  .external-html_external-html__1wWGf .typography-text-container.typography-article-container > ul > li > ol {
    font-size: var(--font-size--medium);
    line-height: var(--line-height--large);
    margin-top: var(--space--small);
    margin-bottom: var(--space--medium); }
  .external-html_external-html__1wWGf .typography-text-container.typography-article-container > ul:first-child {
    margin-top: 0; }
  .external-html_external-html__1wWGf ul li {
    margin: var(--space--xx-small);
    margin-left: var(--space--medium); }

.typography-text-container .external-html_external-html__1wWGf > p {
  line-height: var(--line-height--medium); }
  .typography-text-container .external-html_external-html__1wWGf > p + p {
    margin-top: var(--space--small); }

.typography-text-container .external-html_external-html__1wWGf > h1 {
  margin-bottom: var(--space--xxx-large); }

.typography-text-container .external-html_external-html__1wWGf > h2 {
  font-size: var(--font-size--x-large);
  margin-top: var(--space--xxx-large);
  margin-bottom: var(--space--medium); }

.typography-text-container .external-html_external-html__1wWGf > h3 {
  margin-top: var(--space--medium);
  margin-bottom: var(--space--small); }

.typography-text-container .external-html_external-html__1wWGf > h4 {
  line-height: var(--line-height--medium);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.typography-text-container .external-html_external-html__1wWGf > h5 {
  line-height: var(--line-height--small);
  margin-top: var(--space--small);
  margin-bottom: var(--space--xx-small); }

.typography-text-container .external-html_external-html__1wWGf > h6 {
  line-height: var(--line-height--medium);
  margin-top: var(--space--small);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container .external-html_external-html__1wWGf > p {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large); }
  .typography-text-container.typography-article-container .external-html_external-html__1wWGf > p + p {
    margin-top: var(--space--medium); }

.typography-text-container.typography-article-container .external-html_external-html__1wWGf > h1 {
  font-weight: var(--font-weight--bold); }

.typography-text-container.typography-article-container .external-html_external-html__1wWGf > h2 {
  font-size: var(--font-size--xx-large);
  margin-bottom: var(--space--large); }

.typography-text-container.typography-article-container .external-html_external-html__1wWGf > h3 {
  font-size: var(--font-size--x-large);
  margin-top: var(--space--x-large);
  margin-bottom: var(--space--medium); }

.typography-text-container.typography-article-container .external-html_external-html__1wWGf > h4 {
  font-size: var(--font-size--large);
  margin-top: var(--space--large);
  margin-bottom: var(--space--x-small); }

.typography-text-container.typography-article-container .external-html_external-html__1wWGf > h5 {
  font-size: var(--font-size--medium);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container .external-html_external-html__1wWGf > h6 {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container .external-html_external-html__1wWGf > h1:first-child,
.typography-text-container.typography-article-container .external-html_external-html__1wWGf > h2:first-child,
.typography-text-container.typography-article-container .external-html_external-html__1wWGf > h3:first-child,
.typography-text-container.typography-article-container .external-html_external-html__1wWGf > h4:first-child,
.typography-text-container.typography-article-container .external-html_external-html__1wWGf > h5:first-child,
.typography-text-container.typography-article-container .external-html_external-html__1wWGf > h6:first-child,
.typography-text-container.typography-article-container .external-html_external-html__1wWGf > ol:first-child,
.typography-text-container.typography-article-container .external-html_external-html__1wWGf > ul:first-child {
  margin-top: 0; }
