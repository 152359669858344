.paragraph_line-height-single-line__3wZD_ {
  line-height: var(--line-height--reset) !important; }

.paragraph_alignment-left__2QPXT {
  text-align: left !important; }

.paragraph_alignment-center__xvkNB {
  text-align: center !important; }

.paragraph_alignment-right__3-U_Y {
  text-align: right !important; }

.paragraph_size-x-small__2iOXu {
  font-size: var(--font-size--x-small) !important; }
  .paragraph_size-x-small__2iOXu:not(.paragraph_line-height-single-line__3wZD_) {
    line-height: var(--line-height--small) !important; }

.paragraph_size-small__1IZv7 {
  font-size: var(--font-size--small) !important; }
  .paragraph_size-small__1IZv7:not(.paragraph_line-height-single-line__3wZD_) {
    line-height: var(--line-height--medium) !important; }

.paragraph_size-medium__dfJuw {
  font-size: var(--font-size--medium) !important; }
  .paragraph_size-medium__dfJuw:not(.paragraph_line-height-single-line__3wZD_) {
    line-height: var(--line-height--large) !important; }

.paragraph_size-large__NGPK4 {
  font-size: var(--font-size--large) !important; }
  .paragraph_size-large__NGPK4:not(.paragraph_line-height-single-line__3wZD_) {
    line-height: var(--line-height--medium) !important; }

.paragraph_size-x-large__19vLY {
  font-size: var(--font-size--x-large) !important; }
  .paragraph_size-x-large__19vLY:not(.paragraph_line-height-single-line__3wZD_) {
    line-height: var(--line-height--medium) !important; }

.paragraph_size-xx-large__1uEos {
  font-size: var(--font-size--xx-large) !important; }
  .paragraph_size-xx-large__1uEos:not(.paragraph_line-height-single-line__3wZD_) {
    line-height: var(--line-height--medium) !important; }

.paragraph_size-xxx-large__233OZ {
  font-size: var(--font-size--xxx-large) !important; }
  .paragraph_size-xxx-large__233OZ:not(.paragraph_line-height-single-line__3wZD_) {
    line-height: var(--line-height--small) !important; }

.paragraph_tone-neutral-dark__2nPJM {
  color: var(--color--gray--darker) !important; }

.paragraph_tone-neutral-light__2JHx0 {
  color: var(--color--white) !important; }

.paragraph_tone-subtle__2zcS6 {
  color: var(--color--typography--tone--subtle) !important; }

.paragraph_tone-positive__jFOHS {
  color: var(--color--typography--tone--positive) !important; }

.paragraph_tone-negative__3hC2b {
  color: var(--color--typography--tone--negative) !important; }

.paragraph_tone-warning__CmLob {
  color: var(--color--typography--tone--warning) !important; }

.paragraph_emphasis-normal__SxbZc {
  font-weight: var(--font-weight--normal) !important; }

.paragraph_emphasis-medium__3qo9V {
  font-weight: var(--font-weight--medium) !important; }

.paragraph_emphasis-strong__1wLjM {
  font-weight: var(--font-weight--bold) !important; }

.paragraph_paragraph__1aimy {
  font-family: var(--font-family--default);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--normal);
  color: var(--color--text--static--body);
  margin: 0;
  line-height: var(--line-height--medium); }

.typography-text-container > .paragraph_paragraph__1aimy {
  line-height: var(--line-height--medium); }
  .typography-text-container > .paragraph_paragraph__1aimy + .paragraph_paragraph__1aimy {
    margin-top: var(--space--small); }

.typography-text-container.typography-article-container > .paragraph_paragraph__1aimy {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large); }
  .typography-text-container.typography-article-container > .paragraph_paragraph__1aimy + .paragraph_paragraph__1aimy {
    margin-top: var(--space--medium); }
