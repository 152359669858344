/*
	  This will hide the focus indicator if the element receives focus via the mouse,
	  but it will still show up on keyboard focus.
	  More information at: https://github.com/WICG/focus-visible
	*/
html .tabs_tab__30juB:focus:not(:focus-visible) {
  outline: none; }

html .tabs_tab__30juB::-moz-focus-inner {
  border: 0; }

.tabs_tabs__221nV {
  display: flex;
  height: auto;
  padding: 0;
  overflow-x: auto;
  background-image: linear-gradient(to right, var(--color--background--interactive--default), var(--color--background--interactive--default)), linear-gradient(to right, var(--color--background--interactive--default), var(--color--background--interactive--default)), linear-gradient(to right, rgba(60, 72, 94, 0.25), rgba(255, 255, 255, 0)), linear-gradient(to left, rgba(60, 72, 94, 0.25), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: var(--color--background--interactive--default);
  background-size: 1.25rem 100%, 1.25rem 100%, 0.625rem 100%, 0.625rem 100%;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll; }

.tabs_tab__30juB {
  background-color: transparent;
  padding: var(--space--large);
  border: none;
  transition: box-shadow var(--animation--medium);
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  display: inline-flex;
  align-items: center; }
  .tabs_tab__30juB:first-of-type {
    margin-left: auto; }
  .tabs_tab__30juB:last-of-type {
    margin-right: auto; }
  .tabs_tab__30juB > * + * {
    margin-left: var(--space--xx-small); }
  .tabs_tab__30juB:hover {
    background-color: rgba(60, 72, 94, 0.05);
    box-shadow: inset 0 -0.125rem 0 rgba(60, 72, 94, 0.15); }
  .tabs_tab__30juB:active {
    background-color: var(--color--background--interactive--selected);
    box-shadow: var(--shadow--selected--bottom); }
  .tabs_tab__30juB[aria-selected="true"] {
    box-shadow: var(--shadow--selected--bottom);
    cursor: default; }
  .tabs_tab__30juB:focus-visible {
    box-shadow: inset 0 0 0 0.125rem var(--color--blue); }
  .tabs_tab__30juB:focus-visible[aria-selected="true"] {
    box-shadow: var(--shadow--selected--bottom), inset 0 0 0 0.125rem var(--color--blue); }

/* A Card that directly follows an other Card automatically gets a margin-top. This doesn't work for the first Card that makes up the contents of page-level tabs, because those contents are wrapped in divs, with the tabpanel class.
Likewise, the Card that directly follows a .tabpanel div also doesn't get its usual margin-top.
This code provides Cards in both situations with their missing margin.
When tab content is mounted, only the first .tabpanel div directly follows the Card containing the tabs. To target subsequent .tabpanel divs, the tilde (subsequent-sibling combinator) selector is used. */
.fancy-Card ~ .tabs_tabpanel__3A7Ug,
.tabs_tabpanel__3A7Ug + .fancy-Card {
  margin-top: var(--space--medium); }

.tabs_tab-set-bar__1MiFX {
  max-width: 100%; }
