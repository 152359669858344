.content_content__2xx19.content_padding-small__xltKM {
  padding: var(--space--xx-small); }

.content_content__2xx19.content_padding-compact__2uNEr {
  padding: var(--space--x-small) var(--space--medium); }

.content_content__2xx19.content_padding-medium__2vN0k {
  padding: var(--space--medium); }

.content_content__2xx19.content_padding-large__3auBA {
  padding: var(--space--xx-large); }

.content_layout__2maDM {
  display: flex;
  width: 100%; }
  .content_layout__2maDM.content_padding-small__xltKM {
    padding: var(--space--xx-small); }
  .content_layout__2maDM.content_padding-medium__2vN0k {
    padding: var(--space--x-small); }
  .content_layout__2maDM.content_padding-large__3auBA {
    padding: var(--space--medium); }

@media (max-width: 62rem) {
  .content_layout__2maDM {
    flex-direction: column; } }
