.base-popover_popper-wrapper__17ScN {
  display: inline;
  z-index: 1500;
  transition: opacity var(--animation--fast);
  opacity: 0;
  pointer-events: none; }
  .base-popover_popper-wrapper__17ScN.base-popover_show__1LOIe {
    opacity: 1;
    pointer-events: all; }
  .base-popover_popper-wrapper__17ScN.base-popover_unMounted__1xtUX {
    height: 0; }
