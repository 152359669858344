.typography_line-height-single-line__3R0fB {
  line-height: var(--line-height--reset) !important; }

.typography_alignment-left__2-19c {
  text-align: left !important; }

.typography_alignment-center__19lRA {
  text-align: center !important; }

.typography_alignment-right__2Hicu {
  text-align: right !important; }

.typography_size-x-small__1gCPQ {
  font-size: var(--font-size--x-small) !important; }
  .typography_size-x-small__1gCPQ:not(.typography_line-height-single-line__3R0fB) {
    line-height: var(--line-height--small) !important; }

.typography_size-small__2bgJv {
  font-size: var(--font-size--small) !important; }
  .typography_size-small__2bgJv:not(.typography_line-height-single-line__3R0fB) {
    line-height: var(--line-height--medium) !important; }

.typography_size-medium__WWkti {
  font-size: var(--font-size--medium) !important; }
  .typography_size-medium__WWkti:not(.typography_line-height-single-line__3R0fB) {
    line-height: var(--line-height--large) !important; }

.typography_size-large__2ClpS {
  font-size: var(--font-size--large) !important; }
  .typography_size-large__2ClpS:not(.typography_line-height-single-line__3R0fB) {
    line-height: var(--line-height--medium) !important; }

.typography_size-x-large__2MtqL {
  font-size: var(--font-size--x-large) !important; }
  .typography_size-x-large__2MtqL:not(.typography_line-height-single-line__3R0fB) {
    line-height: var(--line-height--medium) !important; }

.typography_size-xx-large__35xba {
  font-size: var(--font-size--xx-large) !important; }
  .typography_size-xx-large__35xba:not(.typography_line-height-single-line__3R0fB) {
    line-height: var(--line-height--medium) !important; }

.typography_size-xxx-large__3_vw6 {
  font-size: var(--font-size--xxx-large) !important; }
  .typography_size-xxx-large__3_vw6:not(.typography_line-height-single-line__3R0fB) {
    line-height: var(--line-height--small) !important; }

.typography_tone-neutral-dark__1UC5U {
  color: var(--color--gray--darker) !important; }

.typography_tone-neutral-light__lO0st {
  color: var(--color--white) !important; }

.typography_tone-subtle__PvyaY {
  color: var(--color--typography--tone--subtle) !important; }

.typography_tone-positive__13gB4 {
  color: var(--color--typography--tone--positive) !important; }

.typography_tone-negative__1D8NJ {
  color: var(--color--typography--tone--negative) !important; }

.typography_tone-warning__2LUVG {
  color: var(--color--typography--tone--warning) !important; }

.typography_emphasis-normal__2167E {
  font-weight: var(--font-weight--normal) !important; }

.typography_emphasis-medium__7NeEG {
  font-weight: var(--font-weight--medium) !important; }

.typography_emphasis-strong__3Snxo {
  font-weight: var(--font-weight--bold) !important; }
