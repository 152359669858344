.sr-only_sr-only__oYLHp {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }
  .sr-only_sr-only__oYLHp:active, .sr-only_sr-only__oYLHp:focus, .sr-only_sr-only__oYLHp:focus-within {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto; }
