.common_centered-small__10mQZ {
  max-width: 37.5rem;
  width: 100%;
  margin: auto; }

.common_centered-medium__12foi {
  max-width: 56.25rem;
  width: 100%;
  margin: auto; }

.common_gap-xx-small__2Xida {
  gap: var(--space--xx-small); }

.common_gap-x-small__2dcsi {
  gap: var(--space--x-small); }

.common_gap-small__1d_Ir {
  gap: var(--space--small); }

.common_gap-medium__1w-bc {
  gap: var(--space--medium); }

.common_gap-large__2KTVl {
  gap: var(--space--x-large); }
