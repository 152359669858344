.grid_grid__1AlvP {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start; }

.grid_grid-section__1LA5i {
  padding: var(--space--x-small); }

.grid_full__3RkCh {
  width: 100%; }

.grid_threefourths__1kBWD {
  width: 75%; }
  @media (max-width: 75rem) {
    .grid_threefourths__1kBWD {
      width: 100%; } }

.grid_twothirds__3PWDS {
  width: 66.66%; }
  @media (max-width: 75rem) {
    .grid_twothirds__3PWDS {
      width: 100%; } }

.grid_half__1MeU6 {
  width: 50%; }
  @media (max-width: 75rem) {
    .grid_half__1MeU6 {
      width: 100%; } }

.grid_third__8Wxfn {
  width: 33.33%; }
  @media (max-width: 75rem) {
    .grid_third__8Wxfn {
      width: 100%; } }

.grid_fourth__15hl3 {
  width: 25%; }
  @media (max-width: 75rem) {
    .grid_fourth__15hl3 {
      width: 50%; } }
  @media (max-width: 62rem) {
    .grid_fourth__15hl3 {
      width: 100%; } }
