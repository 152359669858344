.badge_badge__3gxIg {
  display: inline-flex;
  align-items: center;
  padding: var(--space--xx-small) var(--space--x-small);
  margin: calc(var(--space--xx-small) / 2);
  white-space: nowrap;
  border-radius: var(--border-radius--small); }
  .badge_badge__3gxIg:empty {
    display: none; }
  .badge_badge__3gxIg p {
    color: var(--color--white); }
  .badge_badge__3gxIg.badge_neutral__2fkOp.badge_dark__22nKI {
    background-color: var(--color--status--neutral--dark); }
  .badge_badge__3gxIg.badge_neutral__2fkOp.badge_light__y3vHv {
    background-color: var(--color--status--neutral--light); }
    .badge_badge__3gxIg.badge_neutral__2fkOp.badge_light__y3vHv p {
      color: var(--color--status--neutral--light--foreground); }
  .badge_badge__3gxIg.badge_subtle__azBCW.badge_dark__22nKI {
    background-color: var(--color--status--subtle--dark); }
  .badge_badge__3gxIg.badge_subtle__azBCW.badge_light__y3vHv {
    background-color: var(--color--status--subtle--light); }
    .badge_badge__3gxIg.badge_subtle__azBCW.badge_light__y3vHv p {
      color: var(--color--status--subtle--light--foreground); }
  .badge_badge__3gxIg.badge_positive__1ql2u.badge_dark__22nKI {
    background-color: var(--color--status--positive--dark); }
  .badge_badge__3gxIg.badge_positive__1ql2u.badge_light__y3vHv {
    background-color: var(--color--status--positive--light); }
    .badge_badge__3gxIg.badge_positive__1ql2u.badge_light__y3vHv p {
      color: var(--color--status--positive--light--foreground); }
  .badge_badge__3gxIg.badge_warning__28mE4.badge_dark__22nKI {
    background-color: var(--color--status--warning--neutral); }
    .badge_badge__3gxIg.badge_warning__28mE4.badge_dark__22nKI p {
      color: var(--color--status--warning--light--foreground); }
  .badge_badge__3gxIg.badge_warning__28mE4.badge_light__y3vHv {
    background-color: var(--color--status--warning--light); }
    .badge_badge__3gxIg.badge_warning__28mE4.badge_light__y3vHv p {
      color: var(--color--status--warning--light--foreground); }
  .badge_badge__3gxIg.badge_critical__gnnod.badge_dark__22nKI {
    background-color: var(--color--status--critical--dark); }
  .badge_badge__3gxIg.badge_critical__gnnod.badge_light__y3vHv {
    background-color: var(--color--status--critical--light); }
    .badge_badge__3gxIg.badge_critical__gnnod.badge_light__y3vHv p {
      color: var(--color--status--critical--light--foreground); }
  .badge_badge__3gxIg.badge_negative__3fMvJ.badge_dark__22nKI {
    background-color: var(--color--status--negative--dark); }
  .badge_badge__3gxIg.badge_negative__3fMvJ.badge_light__y3vHv {
    background-color: var(--color--status--negative--light); }
    .badge_badge__3gxIg.badge_negative__3fMvJ.badge_light__y3vHv p {
      color: var(--color--status--negative--light--foreground); }
  .badge_badge__3gxIg.badge_highlight1__PRm5r.badge_dark__22nKI {
    background-color: var(--color--status--highlight1--dark); }
  .badge_badge__3gxIg.badge_highlight1__PRm5r.badge_light__y3vHv {
    background-color: var(--color--status--highlight1--light); }
    .badge_badge__3gxIg.badge_highlight1__PRm5r.badge_light__y3vHv p {
      color: var(--color--status--highlight1--light--foreground); }
  .badge_badge__3gxIg.badge_highlight2__1WOkK.badge_dark__22nKI {
    background-color: var(--color--status--highlight2--dark); }
  .badge_badge__3gxIg.badge_highlight2__1WOkK.badge_light__y3vHv {
    background-color: var(--color--status--highlight2--light); }
    .badge_badge__3gxIg.badge_highlight2__1WOkK.badge_light__y3vHv p {
      color: var(--color--pink--darker); }
  .badge_badge__3gxIg.badge_highlight3__1rnZc.badge_dark__22nKI {
    background-color: var(--color--status--highlight3--dark); }
  .badge_badge__3gxIg.badge_highlight3__1rnZc.badge_light__y3vHv {
    background-color: var(--color--status--highlight3--light); }
    .badge_badge__3gxIg.badge_highlight3__1rnZc.badge_light__y3vHv p {
      color: var(--color--purple--dark); }
