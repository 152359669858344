/*
	  This will hide the focus indicator if the element receives focus via the mouse,
	  but it will still show up on keyboard focus.
	  More information at: https://github.com/WICG/focus-visible
	*/
html .link_link-container__1EP2i:focus:not(:focus-visible) {
  outline: none; }

html .link_link-container__1EP2i::-moz-focus-inner {
  border: 0; }

.link_link-container__1EP2i {
  display: inline-block;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  text-decoration: inherit; }
  .link_link-container__1EP2i.link_inline-link__1_Ixo .link_link-text__35K82 {
    text-decoration: underline;
    font-size: inherit;
    line-height: inherit; }
    .link_link-container__1EP2i.link_inline-link__1_Ixo .link_link-text__35K82 .link_link-indicator__1ZUZs {
      display: none; }
  .link_link-container__1EP2i:hover .link_link-text__35K82 {
    text-decoration: underline;
    color: var(--color--blue--darker); }
  .link_link-container__1EP2i:active .link_link-text__35K82 {
    text-decoration: underline;
    color: var(--color--blue--darkest); }
  .link_link-container__1EP2i:focus-visible {
    box-shadow: 0 0 0 0.125rem var(--color--blue);
    outline: none; }
  .link_link-container__1EP2i.link_open-new__322vF .link_link-text__35K82 .link_link-indicator__1ZUZs {
    top: 0; }
    .link_link-container__1EP2i.link_open-new__322vF .link_link-text__35K82 .link_link-indicator__1ZUZs .link_up-arrow__16x64 {
      display: inherit; }
    .link_link-container__1EP2i.link_open-new__322vF .link_link-text__35K82 .link_link-indicator__1ZUZs .link_straight-arrow__2B9CW {
      display: none; }

.link_link-text__35K82 {
  color: var(--color--blue);
  font-weight: var(--font-weight--interactive--default);
  text-decoration: none;
  word-break: break-word; }
  .link_link-text__35K82.link_disable-word-break__36MDR {
    word-break: unset; }
  .link_link-text__35K82.link_small__3HRxp {
    font-size: var(--font-size--x-small);
    line-height: var(--line-height--small); }
  .link_link-text__35K82.link_medium__76FOk {
    font-size: var(--font-size--interactive--default);
    line-height: var(--line-height--medium); }
  .link_link-text__35K82.link_large__1T6B_ {
    font-size: var(--font-size--medium);
    line-height: var(--line-height--large); }
  .link_link-text__35K82 .link_link-indicator__1ZUZs {
    display: inline-block;
    position: relative;
    top: -0.0625rem; }
    .link_link-text__35K82 .link_link-indicator__1ZUZs .link_up-arrow__16x64 {
      display: none; }
    .link_link-text__35K82 .link_link-indicator__1ZUZs .link_straight-arrow__2B9CW {
      display: inherit; }
  .link_link-text__35K82.link_unstyled__18Z1X {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    text-decoration: inherit; }
