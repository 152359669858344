.inline-text_line-height-single-line__3FjUU {
  line-height: var(--line-height--reset) !important; }

.inline-text_alignment-left__39oYb {
  text-align: left !important; }

.inline-text_alignment-center__1Pst8 {
  text-align: center !important; }

.inline-text_alignment-right__3brhh {
  text-align: right !important; }

.inline-text_size-x-small__3xWAZ {
  font-size: var(--font-size--x-small) !important; }
  .inline-text_size-x-small__3xWAZ:not(.inline-text_line-height-single-line__3FjUU) {
    line-height: var(--line-height--small) !important; }

.inline-text_size-small__1PUT_ {
  font-size: var(--font-size--small) !important; }
  .inline-text_size-small__1PUT_:not(.inline-text_line-height-single-line__3FjUU) {
    line-height: var(--line-height--medium) !important; }

.inline-text_size-medium__3Q8Zu {
  font-size: var(--font-size--medium) !important; }
  .inline-text_size-medium__3Q8Zu:not(.inline-text_line-height-single-line__3FjUU) {
    line-height: var(--line-height--large) !important; }

.inline-text_size-large__2QQfP {
  font-size: var(--font-size--large) !important; }
  .inline-text_size-large__2QQfP:not(.inline-text_line-height-single-line__3FjUU) {
    line-height: var(--line-height--medium) !important; }

.inline-text_size-x-large__1Pg6c {
  font-size: var(--font-size--x-large) !important; }
  .inline-text_size-x-large__1Pg6c:not(.inline-text_line-height-single-line__3FjUU) {
    line-height: var(--line-height--medium) !important; }

.inline-text_size-xx-large__3CRr6 {
  font-size: var(--font-size--xx-large) !important; }
  .inline-text_size-xx-large__3CRr6:not(.inline-text_line-height-single-line__3FjUU) {
    line-height: var(--line-height--medium) !important; }

.inline-text_size-xxx-large__1WzUP {
  font-size: var(--font-size--xxx-large) !important; }
  .inline-text_size-xxx-large__1WzUP:not(.inline-text_line-height-single-line__3FjUU) {
    line-height: var(--line-height--small) !important; }

.inline-text_tone-neutral-dark__lr0IB {
  color: var(--color--gray--darker) !important; }

.inline-text_tone-neutral-light__WcsVS {
  color: var(--color--white) !important; }

.inline-text_tone-subtle__2ZQwo {
  color: var(--color--typography--tone--subtle) !important; }

.inline-text_tone-positive__1rwmL {
  color: var(--color--typography--tone--positive) !important; }

.inline-text_tone-negative__3OSFA {
  color: var(--color--typography--tone--negative) !important; }

.inline-text_tone-warning__1LaGI {
  color: var(--color--typography--tone--warning) !important; }

.inline-text_emphasis-normal__msvLR {
  font-weight: var(--font-weight--normal) !important; }

.inline-text_emphasis-medium__1aUHx {
  font-weight: var(--font-weight--medium) !important; }

.inline-text_emphasis-strong__2iMjP {
  font-weight: var(--font-weight--bold) !important; }

.inline-text_inline-text__3LxCl {
  font-family: var(--font-family--default);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--normal);
  color: var(--color--text--static--body);
  display: inline;
  line-height: var(--line-height--medium); }
